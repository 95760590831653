import { CredentialField } from "./credential-field";

export class Credential {

  public constructor(credential?: Credential) {
    if (credential) {
      Object.assign(this, credential);
      this.fields = credential.fields.map(x => new CredentialField(x));
    }
  }

  id?: number;
  name: string;

  fields: CredentialField[];

  masterPassword?: string;
}
