import { Component, OnInit } from '@angular/core';
import { PasswordVaultService } from '../password-vault.service';
import { Credential } from '../credential';
import { CredentialField } from '../credential-field';

@Component({
  selector: 'app-password-vault',
  templateUrl: './password-vault.component.html',
  styleUrls: ['./password-vault.component.css']
})
export class PasswordVaultComponent implements OnInit {

  constructor(private passwordVault: PasswordVaultService) { }

  selectedCredential: Credential;
  credentials: Credential[];

  ngOnInit() {
    this.getCredentials();
  }

  getCredentials(): void {
    this.passwordVault.getCredentials()
      .subscribe(credentials => this.credentials = credentials);
  }

  onSelect(credential: Credential): void {
    this.passwordVault.getCredential(credential.id)
      .subscribe(credential => this.selectedCredential = credential);
  }

  onChange(): void {
    this.getCredentials();
  }

  addNew(): void {
    let username = new CredentialField();
    username.isSensitive = false;
    username.name = "Username";

    let password = new CredentialField();
    password.isSensitive = true;
    password.name = "Password";

    this.selectedCredential = new Credential();
    this.selectedCredential.fields = [username, password];

  }

}
