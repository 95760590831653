import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JSONCONTENTTYPE } from './http-headers';
import { LoginModel } from './login-model';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  
  private jwtHelper = new JwtHelperService();

  constructor(private http: HttpClient) { }

  public isAuthenticated(): boolean {
    let token = this.token;
    return token && !this.jwtHelper.isTokenExpired(token);
  }

  private jwtKey: string = "jwt";
  public get token(): string {
    return localStorage.getItem(this.jwtKey);
  }

  public set token(value: string) {
    localStorage.setItem(this.jwtKey, value);
  }

  public logout() {
    localStorage.removeItem(this.jwtKey);
  }

  public login(loginModel: LoginModel): Observable<Object> {
    return this.http
      .post("api/auth/login", loginModel, JSONCONTENTTYPE)
      .pipe(tap(_ => this.token = (<any>_).token));
  }

  public register(loginModel: LoginModel): Observable<Object> {
    return this.http
      .post("api/auth/register", loginModel, JSONCONTENTTYPE)
      .pipe(tap(_ => this.token = (<any>_).token));
  }
}
