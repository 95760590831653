import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AuthenticationService } from './authentication.service';
import { RegisterComponent } from './register/register.component';
import { EqualValidator } from './validators/equal-validator';
import { AuthGuardService } from './auth-guard.service';
import { PasswordVaultComponent } from './password-vault/password-vault.component';
import { CredentialComponent } from './credential/credential.component';
import { CredentialFieldComponent } from './credential-field/credential-field.component';

export function jwtOptionsFactory(authenticationService: AuthenticationService) {
  return {
    tokenGetter: () => {
      return authenticationService.token;
    }
  } 
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    EqualValidator,
    PasswordVaultComponent,
    CredentialComponent,
    CredentialFieldComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    NgbModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthenticationService]
      }
    }),
    RouterModule.forRoot([
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuardService] },
      { path: 'password-vault', component: PasswordVaultComponent, canActivate: [AuthGuardService] }
    ])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
