import { Component, OnInit, Input, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { Credential } from '../credential';
import { PasswordVaultService } from '../password-vault.service';
import { CredentialField } from '../credential-field';
import { Direction } from './direction';
import { CredentialFieldComponent } from '../credential-field/credential-field.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

enum Action {
  None,
  GetPassword,
  Edit
}

@Component({
  selector: 'app-credential',
  templateUrl: './credential.component.html',
  styleUrls: ['./credential.component.css']
})
export class CredentialComponent implements OnInit {

  invalidMasterPassword: boolean;
  clone: Credential;
  private passwordsRetrieved: boolean;
  private action: Action;
  @Output() change = new EventEmitter();
  @ViewChildren('credentialField') fieldComponents: QueryList<CredentialFieldComponent>;

  private _credential: Credential;
  get credential(): Credential {
    return this._credential;
  };

  @Input() set credential(credential: Credential) {
    this._credential = credential;
    this.reset();
  };

  constructor(private passwordVault: PasswordVaultService, private modalService: NgbModal) { }

  ngOnInit() {
    this.reset()
  }

  private isNew(): boolean {
    return this.credential && this.credential.id == null;
  }

  reset() {
    this.passwordsRetrieved = false;
    this.action = this.isNew() ? Action.Edit : Action.None;
    this.invalidMasterPassword = false;
  }

  clearPasswords() {
    this.passwordsRetrieved = false;
    for (let field of this.credential.fields) {
      if (field.isSensitive) {
        field.value = null;
      }
    }
  }

  submit() {
    switch (this.action) {
      case Action.GetPassword: {
        this.submitGetPasswords();
        break;
      }
      case Action.Edit: {
        this.submitEdit();
        break;
      }
    }
  }

  submitGetPasswords() {
    this.passwordVault.getPasswords(this.credential.id, this.credential.masterPassword)
      .subscribe(
        credential => {
          this.credential = credential;
          this.credential.masterPassword = null;
          this.invalidMasterPassword = false;
          this.action = Action.None;
          this.passwordsRetrieved = true;
        },
        err => {
          this.invalidMasterPassword = true;
        });
  }

  submitEdit() {
    this.passwordVault.save(this.credential)
      .subscribe(
      credential => {
          this.credential = credential;
          this.credential.masterPassword = null;
          this.invalidMasterPassword = false;
          this.clearPasswords();
          this.action = Action.None;
          this.change.emit();
        },
        err => {
          this.invalidMasterPassword = true;
        });
  }

  getPasswords() {
    this.action = Action.GetPassword;
  }

  edit() {
    this.clone = new Credential(this.credential);
    this.clearPasswords();
    this.action = Action.Edit;
  }

  cancelAction() {
    if (this.isNew()) {
      this.credential = null;
    }
    else if (this.action == Action.Edit) {
      this.credential = this.clone;
    }

    this.reset();
  }

  displayActions() : boolean {
    return this.action == Action.None;
  }

  onMove(field: CredentialField, direction: Direction) {

    let index: number = this.credential.fields.indexOf(field);
    let swapIndex: number = index;

    switch (direction) {
      case Direction.Up: {
        swapIndex--; 
        break;
      }
      case Direction.Down: {
        swapIndex++;
        break;
      }
    }

    this.credential.fields[index] = this.credential.fields[swapIndex];
    this.credential.fields[swapIndex] = field;
  }

  isFirst(field: CredentialField): boolean {
    return this.credential.fields[0] == field;
  }

  isLast(field: CredentialField): boolean {
    return this.credential.fields[this.credential.fields.length - 1] == field;
  }

  showSubmit(): boolean {
    return this.action != Action.None;
  }
  
  editMode(): boolean {
    return this.action == Action.Edit;
  }

  onDelete(field: CredentialField) {
    let index: number = this.credential.fields.indexOf(field);
    this.credential.fields.splice(index, 1);
  }
  
  isMasterPasswordRequired(): boolean {
    if (this.action == Action.GetPassword) {
      return true;
    }

    let result: boolean = false;
    this.fieldComponents.forEach((fieldComponent) => {
      result = result || fieldComponent.confirmValue()
    });
    
    return result;
  }

  areTherePasswords(): boolean {
    return this.credential.fields.some((credField: CredentialField) => credField.isSensitive);
  }

  addInsenstive() {
    let field: CredentialField = new CredentialField();
    field.isSensitive = false;
    this.credential.fields.push(field);
  }

  addSenstive() {
    let field: CredentialField = new CredentialField();
    field.isSensitive = true;
    this.credential.fields.push(field);
  }

  delete(): void {
    this.passwordVault.deleteCredential(this.credential)
      .subscribe(
      result => {
        this.modalService.dismissAll();
          this.credential = null;
          this.change.emit();
      },
      err => {
        alert(err);
      });
  }

  open(content) {
    this.modalService.open(content).result;
  }
}
