import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, FormControl } from '@angular/forms';

@Directive({
  selector: '[validateEqual]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EqualValidator, multi: true }]
})
export class EqualValidator implements Validator {

  // TODO I don't know the scopes of custom validators so don't know wether this will work???
  // Check out angualr tutorial where this is placed on the parent control
  // A better solution might be to make a sub control group that contains both password and match password

  private hasSubscribed: boolean = false;
  @Input('validateEqual') compareControl: FormControl;

  validate(control: AbstractControl): { [key: string]: any } {
    if (this.compareControl && control.value && this.compareControl.value) {
      if (!this.hasSubscribed) {
        this.hasSubscribed = true;
        this.compareControl.valueChanges.subscribe(() => {
          control.updateValueAndValidity();
        });
      }

      if (this.compareControl.value != control.value) {
        return { notEqual: true };
      }
    }
    return null;
  }
}
