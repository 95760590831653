import { Component } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { RegisterModel } from '../register-model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {

  model: RegisterModel = new RegisterModel();
  invalidLogin: boolean;

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  register() {
    this.authenticationService
      .register(this.model)
      .subscribe(response => {
        this.invalidLogin = false;
        this.router.navigate(["/"]);
      }, err => {
        this.invalidLogin = true;
      });
  }

}
