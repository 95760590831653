import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Credential } from './credential';
import { JSONCONTENTTYPE } from './http-headers';

@Injectable({
  providedIn: 'root'
})
export class PasswordVaultService {

  constructor(private http: HttpClient) { }

  getCredentials(): Observable<Credential[]> {
    return this.http.get<Credential[]>("api/PasswordVault");
  }

  getCredential(id: number): Observable<Credential> {
    return this.http.get<Credential>("api/PasswordVault/" + id);
  }

  getPasswords(id: number, masterPassword: string): Observable<Credential> {
    return this.http.post<Credential>("api/PasswordVault/RetrievePassword", { id: id, masterPassword: masterPassword}, JSONCONTENTTYPE);
  }

  save(credential: Credential): Observable<Credential> {
    let route = credential.id == null ? "api/PasswordVault/Add" : "api/PasswordVault/Update";
    return this.http.post<Credential>(route, credential, JSONCONTENTTYPE);
  }

  deleteCredential(credential: Credential): Observable<Object> {
    return this.http.post("api/PasswordVault/Delete/" + credential.id, JSONCONTENTTYPE);
  }

}
