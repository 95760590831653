import { Component } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { LoginModel } from '../login-model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  model: LoginModel = new LoginModel();
  invalidLogin: boolean;

  constructor(private router: Router, private authenticationService: AuthenticationService) { }
  
  login() {
    this.authenticationService
      .login(this.model)
      .subscribe(response => {
        this.invalidLogin = false;
        this.router.navigate(["/"]);
      }, err => {
        this.invalidLogin = true;
      });
  }

}
