import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CredentialField } from '../credential-field';
import { Direction } from '../credential/direction';
import { ControlContainer, NgForm } from '@angular/forms';
import { UniqueKeyService } from '../unique-key.service';

@Component({
  selector: 'app-credential-field',
  templateUrl: './credential-field.component.html',
  styleUrls: ['./credential-field.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class CredentialFieldComponent implements OnInit {
  
  @Input() field: CredentialField;
  @Input() isFirst: boolean;
  @Input() isLast: boolean;
  @Input() editMode: boolean;
  @Output() move = new EventEmitter<Direction>();
  @Output() remove = new EventEmitter();
  showPassword: boolean = false;
  editValue: boolean = false;
  confirmPasswordValue: string;

  private key: number;

  constructor(private uniqueKeyService: UniqueKeyService) { }

  ngOnInit() {
    this.key = this.uniqueKeyService.getNextKey();
  }

  get type() {
    return !this.field.isSensitive || this.showPassword ? "text" : "password";
  }

  showRevealPasswordButtons() {
    return this.field.isSensitive
      && !this.editMode
      && (this.field.value != null);
  }

  delete() {
    this.remove.emit();
  }

  moveUp() {
    this.move.emit(Direction.Up);
  }

  moveDown() {
    this.move.emit(Direction.Down);
  }

  copyValueToClipboard() {
    this.copyToClipboard(this.field.value);
  }

  valueOptional(): boolean {
    return this.field.isSensitive && (this.field.id != null);
  }

  valueRequired(): boolean {
    return this.editMode && (!this.valueOptional() || this.editValue);
  }

  public confirmValue(): boolean {
    return this.field.isSensitive && this.valueRequired();
  }

  private copyToClipboard(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
