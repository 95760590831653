export class CredentialField {

  public constructor(field?: CredentialField) {
    if (field) {
      Object.assign(this, field);
    }
  }

  id?: number;
  name: string;
  isSensitive: boolean;
  value: string;
}
